<template>
  <div class="internship">
    <h3 class="internship-title">
      <span class="internship-title-title">{{ title }}</span>
      <span class="internship-title-place">{{ place }}</span>
      <span class="internship-title-dates">{{ capitalizeFirstChar(beginDate) }}  - {{ capitalizeFirstChar(endDate) }} ({{ duration }})</span>
    </h3>
    <ul class="internship-description">
      <li v-for="(descItem, ix) in description" :key="`description-item-${ix}`">
        {{ descItem}}
      </li>
    </ul>
    <p class="internship-email">
      {{ tutor }}
    </p>
  </div>
</template> 

<script>
  import useFormat from "@/helpers/format"

  export default {
    name: "Internship",
    props: {
      title: { type: String, required: true },
      tutor: { type: String, required: true },
      subject: { type: String, required: true },
      beginDate: { type: String, required: true },
      endDate: { type: String, required: true },
      duration: { type: String, required:true },
      place: { type: String, required: true },
      description: { type: Array, required: true },
    },
    setup() {
      const { capitalizeFirstChar } = useFormat

      return { capitalizeFirstChar: capitalizeFirstChar }
    }
  }
</script>

<style lang="scss" scoped>
  .internship {
    color: var(--contrast-color);
    border: 1px solid var(--main-color-accent);
    padding: 0 2rem;
    margin: 1rem 0;

    &-title {
      color: var(--main-color-accent);

      &-title { display: block; }

      &-place {
        color: var(--contrast-color);
        font-size: 1rem;
      }

      &-dates {
        color: var(--contrast-color);
        font-weight: normal;
        font-size: 1rem;
        margin-left: 1rem;
      }
    }

    &-email {
      text-align: right;

      a, a:hover, a:visited, a:active, a:focus, a:target {
        color: white;
        text-decoration: none;
      }
    }
  }
  
  @include mobile {
    .internship {
      padding: 0 1rem;

      &-title {
        text-align: center;
        color: var(--main-color-accent);

        &-place {
          display: block;
          margin-top: 0.5rem;
        }

        &-dates {
          margin-top: 0.5rem;
          display: block;
        }
      }
    }
  }
</style>