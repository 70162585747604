<template>
  <div class="about">
    <span class="about-back" @click="goBack">
      <font-awesome-icon icon="arrow-left" /> {{ $t("back") }}
    </span>

    <h1>{{ $t("about.title") }}</h1>

    <a class="about-download" href="/documents/resume.pdf" target="blank">
      <font-awesome-icon icon="download" /> {{ $t("about.download-resume") }}
    </a>

    <div>
      <h2>{{ $t("about.publications-title") }}</h2>
      
      <publication title="H89 Treatment Reduces Intestinal Inflammation and Candida albicans Overgrowth in Mice"
        :parutionDate="DateTime.fromObject({day: 19, month: 12, year: 2020}).toLocaleString({day: 'numeric', month: 'long', year: 'numeric'})"
        :authors="['Corentin Dumortier', 'Rogatien Charlet', 'Ali Bettaieb', 'Samir Jawhara']"
        link="https://pubmed.ncbi.nlm.nih.gov/33352792/" />

      <h2>{{ $t("about.experiences-title") }}</h2>

      <professional-experience :title="$t('about.experiences.inserm.title')"
        :beginDate="DateTime.fromObject({month: 1, year: 2020}).toLocaleString({month: 'long', year: 'numeric'})"
        :endDate="DateTime.fromObject({month: 8, year: 2021}).toLocaleString({month: 'long', year: 'numeric'})"
        :duration="`1 ${$t('about.experiences.year')} 8 ${$t('about.experiences.months')}`"
        :place="$t('about.experiences.inserm.place')"
        :description="[
          $t('about.experiences.inserm.description-1'),
          $t('about.experiences.inserm.description-2'),
          $t('about.experiences.inserm.description-3'),
          $t('about.experiences.inserm.description-4'),
          $t('about.experiences.inserm.description-5')
        ]"
        contact="sophie.halliez@inserm.fr" />
      <professional-experience :title="$t('about.experiences.bio-rad.title')"
        :beginDate="DateTime.fromObject({month: 8, year: 2019}).toLocaleString({month: 'long', year: 'numeric'})"
        :endDate="DateTime.fromObject({month: 9, year: 2019}).toLocaleString({month: 'long', year: 'numeric'})"
        :duration="`2 ${$t('about.experiences.months')}`"
        :place="$t('about.experiences.bio-rad.place')"
        :description="[
          $t('about.experiences.bio-rad.description-1'),
          $t('about.experiences.bio-rad.description-2'),
          $t('about.experiences.bio-rad.description-3'),
          $t('about.experiences.bio-rad.description-4'),
          $t('about.experiences.bio-rad.description-5')
        ]"
        contact="yann_ciczora@bio-rad.com" />

      <h2>{{ $t("about.trainings-title") }}</h2>

      <training :title="$t('about.trainings.master.title')"
        examDate="2021"
        :speciality="$t('about.trainings.master.speciality')" />
      <training :title="$t('about.trainings.experiment.title')"
        examDate="2019"
        :speciality="$t('about.trainings.experiment.speciality')" />
      <training :title="$t('about.trainings.licence.title')"
        examDate="2019"
        :speciality="$t('about.trainings.licence.speciality')" />
      <training :title="$t('about.trainings.bts.title')"
        examDate="2018"
        speciality="" />
      <training :title="$t('about.trainings.bac.title')"
        examDate="2016"
        :speciality="$t('about.trainings.bac.speciality')" />

      <h2>{{ $t("about.internships-title") }}</h2>

      <internship :title="`${$t('about.internships.licence.title')}`"
        :place="$t('about.internships.licence.place')"
        :tutor="$t('about.internships.licence.tutor')"
        :beginDate="DateTime.fromObject({month: 11, year: 2018}).toLocaleString({month: 'long', year: 'numeric'})"
        :endDate="DateTime.fromObject({month: 2, year: 2019}).toLocaleString({month: 'long', year: 'numeric'})"
        :duration="`4 ${$t('about.experiences.months')}`"
        :subject="$t('about.internships.licence.first-title')"
        :description="[
          $t('about.internships.licence.description-1'),
          $t('about.internships.licence.description-2'),
          $t('about.internships.licence.description-3'),
          $t('about.internships.licence.description-4'),
          $t('about.internships.licence.description-5')
        ]" />

      <internship :title="`${$t('about.internships.bts.title')} - ${$t('about.internships.bts.second-title-year')}`"
        :place="$t('about.internships.bts.place')"
        :tutor="$t('about.internships.bts.tutor')"
        :beginDate="DateTime.fromObject({month: 12, year: 2017}).toLocaleString({month: 'long', year: 'numeric'})"
        :endDate="DateTime.fromObject({month: 2, year: 2018}).toLocaleString({month: 'long', year: 'numeric'})"
        :duration="`2 ${$t('about.experiences.months')}`"
        :subject="$t('about.internships.bts.second-title')"
        :description="[
          $t('about.internships.bts.description-second-1'),
          $t('about.internships.bts.description-second-2'),
          $t('about.internships.bts.description-second-3'),
          $t('about.internships.bts.description-second-4')
        ]" />

      <internship :title="`${$t('about.internships.bts.title')} - ${$t('about.internships.bts.first-title-year')}`"
        :place="$t('about.internships.bts.place')"
        :tutor="$t('about.internships.bts.tutor')"
        :beginDate="DateTime.fromObject({month: 6, year: 2016}).toLocaleString({month: 'long', year: 'numeric'})"
        :endDate="DateTime.fromObject({month: 7, year: 2016}).toLocaleString({month: 'long', year: 'numeric'})"
        :duration="`2 ${$t('about.experiences.months')}`"
        :subject="$t('about.internships.bts.first-title')"
        :description="[
          $t('about.internships.bts.description-first-1'),
          $t('about.internships.bts.description-first-2'),
          $t('about.internships.bts.description-first-3'),
          $t('about.internships.bts.description-first-4')
        ]" />

    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { DateTime } from 'luxon'

  import Publication from "@/components/about/Publication"
  import ProfessionalExperience from "@/components/about/ProfessionalExperience"
  import Training from "@/components/about/Training"
  import Internship from "@/components/about/Internship"

  export default {
    name: "About",
    components: { Publication, ProfessionalExperience, Training, Internship },
    setup() {
      const router = useRouter()

      const goBack = () => {
        router.push('/')
      }
      return { goBack, DateTime }
    }
  }
</script>

<style lang="scss" scoped>
  .about {
    color: var(--contrast-color);
    padding: 1rem;
    height: calc(100% - 3rem);
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 4px var(--main-color-accent);
      // border: 1px solid var(--main-color-accent);
      border-radius: 1rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--main-color-accent);
      border-radius: 1rem;
    }

    &-download {
      background: none;
      border: 1px solid var(--main-color-accent);
      color: var(--main-color-accent);
      padding: 0.5rem;
    }

    &-back {
      cursor: pointer;
    }

    h1 {
      text-align: center;
      color: var(--main-color-accent)
    }
  }
</style>