<template>
  <div class="training">
    <h3 class="training-title">
      {{ title }} 
      <span class="training-title-speciality">{{ speciality }}</span>
      <span class="training-title-dates">{{ capitalizeFirstChar(examDate) }}</span>
    </h3>
    <p class="training-email">
      <a :href="`mailto:${contact}`">{{ contact }}</a>
    </p>
  </div>
</template> 

<script>
  import useFormat from "@/helpers/format"

  export default {
    name: "Training",
    props: {
      title: { type: String, required: true },
      examDate: { type: String, required: true },
      speciality: { type: String, required: true }
    },
    setup() {
      const { capitalizeFirstChar } = useFormat

      return { capitalizeFirstChar: capitalizeFirstChar }
    }
  }
</script>

<style lang="scss" scoped>
  .training {
    color: var(--contrast-color);
    border: 1px solid var(--main-color-accent);
    padding: 0 2rem;
    margin: 1rem 0;

    &-title {
      color: var(--main-color-accent);

      &-speciality {
        color: var(--contrast-color);
        margin-left: 1rem;
        font-size: 1rem;
      }

      &-dates {
        color: var(--contrast-color);
        font-weight: normal;
        margin-left: 1rem;
      }
    }

    &-email {
      text-align: right;
      
      a, a:hover, a:visited, a:active, a:focus, a:target {
        color: white;
        text-decoration: none;
      }
    }
  }
  
  @include mobile {
    .training {
      padding: 0 1rem;

      &-title {
        text-align: center;
        color: var(--main-color-accent);

        &-place {
          display: block;
          margin-top: 0.5rem;
        }

        &-dates {
          margin-top: 0.5rem;
          display: block;
        }
      }
    }
  }
</style>